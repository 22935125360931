@import './config';

@font-face {
  font-family: 'LibreBaskerville';
  src: url('./LibreBaskerville-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LibreBaskerville';
  src: url('./LibreBaskerville-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LibreBaskerville';
  src: url('./LibreBaskerville-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

html {
    width: 100%;
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: $font-primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    background-color: $background-color;
  }
  
  input,
  textarea,
  button,
  a {
    font-family: $font-primary;
  }
  
  h1 {
    padding: 0;
    margin: 0;
  }
  
  button {
    cursor: pointer;
  }
  
  .root {
    height: 100%;
    overflow: scroll;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
  
  .__react_component_tooltip {
    z-index: 99999 !important;
  }

  .main-container {
    background-image: url("../images/landing_main.png");
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .end-container {
    border-top: 2px solid black;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    div {
      display: grid;
      place-items: center;
      margin-bottom: 70px;
    }

    h1 {
      font-family: "LibreBaskerville";
    }
  }

  .copyright-notice {
    width: 100%;
    font-size: 14px;
    background-color: rgb(50,50,50);
    padding: 10px 15px;
  }

  @media (max-width: 900px) {
    .main-container {
      background-image: none;
    }
  }