.grid-container {
    display: flex; /* Use flexbox for the grid layout */
    flex-wrap: wrap; /* Allow elements to wrap to the next line if necessary */
  }
  
  .grid-element {
    min-width: 200px; /* Set a minimum width for the elements */
  }

  .demo-screen {
    position: relative;
    flex: 0 0 60%;
    display: flex;
    justify-content: center;


    img {
        width: 100%;
        object-fit: cover;
    }
  }

  .pitch-block {
    flex: 0 0 40%;
    display: flex;
    justify-content: center;

    .inner-pitch-block {
        margin-top: 20%;
    }
  }

  .pitch-title {
    font-family: "LibreBaskerville", "Roboto";
    color: #f17300;
    font-weight: bolder;
    font-size: 3rem;
    margin: 0px;
    padding: 0px;
  }
  
  p {
    font-size: 1.5rem;
    font-weight: 300;
    color: white;
    margin: 0px;
    padding: 0px;
    width: 80%;
  }
  
  /* Media query for mobile or smaller screens */
  @media (max-width: 900px) {
    .grid-element {
        flex: 100%;
    }

    .grid-container {
        background-color: #054a91;
    } 

    .pitch-block {
        margin-bottom: 60px;

        .inner-pitch-block {
            margin-top: 10%;
        }
    }
  }