.functionality-header {
    margin-top: 30px;
    margin-bottom: 50px;
    color: #3e7cb1;
    font-size: 3.5rem;
    font-family: "LibreBaskerville", "Roboto";
    text-align: center;
}

.bipanel-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.bipanel-container-left {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.bipanel-element {
    flex: 50%;
}

.bipanel-text {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        font-family: "LibreBaskerville", "Roboto";
    }

    h3 {
        font-size: 1.8rem;
    }

    p {
        font-size: 1.2rem;
        color: black;
        margin: 0;
        padding: 0;
        width: 400px;
    }
}

.bipanel-supplement {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.bipanel-supplement-left {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.number-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid black;
    border-radius: 50%;
    height: 320px;
    width: 320px;
  }
  
  .number {
    font-size: 8rem;
    font-weight: bold;
  }

  .color-2 {
    color: #3e7cb1
  }

  .border-color-2 {
    border-color: #3e7cb1;
  }

  .color-5 {
    color: #f17300;
  }

  .border-color-5 {
    border-color: #f17300;
  }

@media (max-width: 900px) {
    .bipanel-container {
        flex-direction: column-reverse;
    }

    .bipanel-element {
        flex: 100%;
        margin-bottom: 20px;
        padding: 0px 10%;
    }

    .bipanel-text {
        h3 {
            text-align: center;
        }

        p {
            text-align: center;
        }
    }
}