.header-container {
    padding: 15px;
    padding-left: 25px;
    display: flex;
    align-items: center;
}

.logo-wide-container {
    width: 150px;
    padding: 15px;
    border-radius: 5px;
    margin-right: 15px;
    transition: background-color 0.2s;
}

.logo-wide-container:hover {
    background-color: #1e67b0;
    cursor: pointer;
}

.logo-wide-source {
    width: 150px;
}

.sectional {
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 18px;
    color: #dbe4ee;

    a {
        text-decoration: none;
        color: inherit
    }
}

.sectional:hover {
    color: #f17300;
    cursor: pointer;
}

  /* Media query for mobile or smaller screens */
  @media (max-width: 900px) {
    .header-container {
        background-color: #054a91;
    }
  }